define("ember-yeti-table/components/yeti-table/body/component", ["exports", "@ember-decorators/component", "@ember/component", "@ember/object", "ember-yeti-table/components/yeti-table/body/template"], function (_exports, _component, _component2, _object, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _class2;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  /**
    Renders a `<tbody>` element and yields the row component, row data and index.
    ```hbs
    <table.body as |body person index|>
      <body.row as |row|>
        <row.cell>
          {{person.firstName}} #{{index}}
        </row.cell>
        <row.cell>
          {{person.lastName}}
        </row.cell>
        <row.cell>
          {{person.points}}
        </row.cell>
      </body.row>
    </table.body>
    ```
    It can also be used as a blockless component to let yeti table automatically
    unroll thee rows for you, based on the `@prop` arguments you passed in to the
    column definition components.
    ```hbs
    <table.body/>
    ```
    Remember that this component's block will be rendered once per each item in the `@data` array.
    @yield {object} body
    @yield {Component} body.row - the row component
    @yield {Object} rowData - one item in the data array
    @yield {number} index
  */
  let Body = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec(_class = _dec2(_class = (_class2 = class Body extends _component2.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "theme", void 0);
      _defineProperty(this, "data", void 0);
      _defineProperty(this, "columns", void 0);
      _defineProperty(this, "parent", void 0);
      /**
       * Adds a click action to each row, called with the clicked row's data as an argument.
       * Can be used with both the blockless and block invocations.
       */
      _defineProperty(this, "onRowClick", void 0);
    }
    handleRowClick(rowData) {
      var _this$onRowClick;
      (_this$onRowClick = this.onRowClick) === null || _this$onRowClick === void 0 || _this$onRowClick.call(this, rowData);
    }
  }, (_applyDecoratedDescriptor(_class2.prototype, "handleRowClick", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleRowClick"), _class2.prototype)), _class2)) || _class) || _class);
  var _default = _exports.default = Body;
});