define("ember-yeti-table/components/yeti-table/tfoot/component", ["exports", "@ember-decorators/component", "@ember/component", "@ember/debug", "ember-yeti-table/components/yeti-table/tfoot/template"], function (_exports, _component, _component2, _debug, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  /**
    Renders a `<tfoot>` element and yields the row component.
    ```hbs
    <table.tfoot as |foot|>
      <foot.row as |row|>
        <row.cell>
          Footer content
        </row.cell>
      </foot.row>
    </table.tfoot>
    ```
  
    @yield {object} footer
    @yield {Component} footer.row
  */
  let TFoot = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec(_class = _dec2(_class = class TFoot extends _component2.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "theme", void 0);
      _defineProperty(this, "parent", void 0);
      _defineProperty(this, "columns", void 0);
    }
    init() {
      super.init(...arguments);
      (false && !(!this.shouldDeprecate) && (0, _debug.deprecate)('The yielded `foot` component has been deprecated. Please use `tfoot` instead.', !this.shouldDeprecate, {
        id: 'ember-yeti-table:foot-component',
        until: '2.0.0',
        for: 'ember-yeti-table',
        since: {
          enable: '1.4.0'
        }
      }));
    }
  }) || _class) || _class);
  var _default = _exports.default = TFoot;
});