define("ember-svg-jar/inlined/marketplace-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M4.383 12.654h6.03v.071c0 .524.056 1.034.163 1.525H4.383c-.42 0-.792-.16-1.114-.482-.321-.322-.482-.693-.482-1.114V7.162l-.223-.223c-.52-.643-.68-1.397-.482-2.263l.816-3.489c.075-.346.26-.63.557-.853C3.752.111 4.073 0 4.42 0h10.984c.347 0 .656.111.928.334.297.223.495.507.594.853l.816 3.489c.077.335.1.653.069.955a7.23 7.23 0 00-1.67.126 1.05 1.05 0 00.08-.71l-.854-3.488-1.521.037.482 3.822c.025.247.124.47.297.668.037.037.076.07.117.1-.9.381-1.705.943-2.37 1.64a2.589 2.589 0 01-.642.078c-.668 0-1.274-.247-1.818-.742-.495.495-1.076.742-1.744.742-.742 0-1.36-.247-1.856-.742-.445.495-1.026.742-1.744.742h-.185v4.75zm-.78-7.607c-.074.346 0 .655.223.928.173.247.42.37.742.37.52 0 .829-.309.928-.927l.482-3.822H4.42l-.817 3.45zm3.526.223c0 .272.086.52.26.742a.914.914 0 00.705.334c.296 0 .544-.1.742-.297a1.09 1.09 0 00.297-.742V1.596H7.574L7.13 5.158v.112zm3.562-3.674v3.71c0 .273.1.52.297.743.198.198.42.297.668.297a.993.993 0 00.78-.334.994.994 0 00.26-.854l-.446-3.562H10.69zM21.23 12.312a.858.858 0 01.048.282c0 .125-.016.234-.048.328 0 .094-.016.203-.049.328l1.212.984-1.066 1.735-1.502-.469a4.2 4.2 0 01-1.114.61l-.29 1.5h-2.084l-.29-1.5a4.2 4.2 0 01-1.115-.61l-1.502.469-1.017-1.735 1.162-.984a1.336 1.336 0 01-.048-.328v-.609c0-.126.016-.235.048-.329l-1.162-1.03 1.017-1.735 1.502.515c.323-.28.694-.5 1.114-.656l.29-1.453h2.084l.29 1.453c.42.156.792.375 1.115.656l1.502-.515 1.066 1.734-1.212 1.031c.033.094.049.204.049.329zm-5.33 1.735c.42.375.921.562 1.503.562.549 0 1.017-.187 1.405-.562.42-.406.63-.89.63-1.453 0-.531-.21-.985-.63-1.36a1.87 1.87 0 00-1.405-.609c-.582 0-1.082.203-1.502.61a1.822 1.822 0 00-.582 1.359c0 .562.194 1.047.582 1.453z\"/>",
    "attrs": {
      "width": "24",
      "height": "18",
      "viewBox": "0 0 24 18",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});